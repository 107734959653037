import React from 'react';
import { isNumeric } from '../../../utils';

const rand = (max=10000) => Math.floor(Math.random() * Math.floor(max));

export const PricingTable = ({data, cols, getChargeToAdd, isPresentation=false, charge_data, ...props}) => {
    const cols_keys = cols.map(c => isNumeric(c.key) && parseInt(c.key));
    const c_data = getChargeToAdd({data, cols}, null, true);
    const matrix_data = charge_data && charge_data.decoration && charge_data.decoration.matrix ?
                        charge_data.decoration.matrix.data : [];

    // Charge to be applied
    let c = !charge_data['charge_to_add'] && c_data ? c_data['charge'] : charge_data['charge_to_add'];
    const c_col = !charge_data['charge_to_add_col'] && c_data && c_data['colData'] ? c_data['colData']['idx'] : charge_data['charge_to_add_col'];

    let prev_charge = 1;
    if (data && data.length && data[0]['id'] && matrix_data.length>0) {
        const idx = parseInt(data[0]['id']);
        if (idx > 1) {
            prev_charge = matrix_data[idx-2] ? parseInt(matrix_data[idx-2]['-'])+1 : '';
        }
    }

    if (matrix_data && matrix_data.length && data[0]['price_by_type'] == 'per_additional_unit') {
        data = matrix_data.filter(v => isNumeric(v['id']) && parseInt(v['id']) >= parseInt(data[0]['id'])-1);
    }

    function renderRowDef(r) {
        if (r['price_by_type'] == 'per_additional_unit') {
            return 'Each Additional ' + r['-'];
        }
        else if (prev_charge == r['-'] || prev_charge=='') {
            return r['-'];
        } else {
            return prev_charge + (data[data.length-1]['price_by_type'] != 'per_additional_unit'
                ? ' - ' +  r['-'] : ' +');
        }
    }

    function getColStyles(r, k) {
        if (r[k] == c && k == c_col && data[data.length-1]['price_by_type'] != 'per_additional_unit') {
            return {
                background: '#65b6cc',
                color: 'white',
                fontWeight: 'bold',
            };
        }
        return {};
    }

    return (
        <div className="columns medium-12">
            <table border="0" className="table">
                <thead style={{border: 'none'}}>
                    <tr>
                        <th style={{textAlign: 'center', }}>
                            {charge_data.d_unit}
                        </th>
                        {cols.map(c => {
                            return (c.label == '-' ? null :
                                <th key={`col_${c.label}_${rand()}`} style={{textAlign: 'center'}}>
                                    {c.label} {(c.until && c.until != '0') ? '- ' + c.until : '+'}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody style={{border: 'none', textAlign: 'center'}}>
                    {data.map((r, i) => (
                    <tr key={`row_${r.id}_${rand()}`}>
                        <th key={`row_th_${r.id}_${r['-']}_${rand()}_${i}`} style={{textAlign: 'center'}}>
                            {renderRowDef(r)}
                        </th>

                        {Object.keys(r).map((k, j) => {
                            if (isNumeric(k) && cols_keys.includes(parseInt(k))) {
                                return <td style={{textAlign: 'center', ...getColStyles(r, k)}}
                                    key={`row_td_${r.id}_${r['-']}_${r[k]}_${rand()}_${i}_${j}`}
                                    onClick={(e) => {
                                        if (props.charge_type == 'run' && !isPresentation && data[0][k] != '') {
                                            c = r[k];
                                            if (data[data.length-1]['price_by_type'] == 'per_additional_unit') {
                                                // if click on 2nd last row then use values from last row cause 2nd last row values already being used
                                                // last row of matrix is each additional
                                                if (r['price_by_type'] != 'per_additional_unit') {
                                                    c = data[data.length-1][k];
                                                }

                                                c = props.getPerAdditionalUnitCharge({
                                                    charge: c,
                                                    chargeKey: k
                                                });
                                            }

                                            props.updateChargesData({charge_to_add: c, charge_to_add_col: k});
                                        }
                                    }}>{r[k] ? '$'+r[k] : ''}</td>;
                            }
                        })}
                    </tr>))}
                </tbody>
            </table>

            {((c_data === false || !c) && !isPresentation)
                ? <div className="columns medium-12" style={{textAlign: 'center'}}>
                    <p style={{color: 'black', textAlign: 'center'}}>There is no charge matching the given quantity</p>
                </div>
                : c && <p style={{textAlign: 'center', fontWeight: '600'}}>This charge will be applied: ${c}</p>}
        </div>
    );
};
