import React, { Component } from 'react';
import TextInput from '../../TextInput';
import { convertToFloat } from '../../../admin-dec-charges-utils';

const Label = (value, htmlFor_, props) => (<label htmlFor={htmlFor_ ? htmlFor_ : '-'} style={{textAlign: 'left'}} {...props}>{value}</label>);

export class ChargeListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
            removing: false,
            cell_value: this.props.charge_data && this.props.charge_data.cell_value || '',
        };

        this._hoverOn = this.toggleHover(true);
        this._hoverOff = this.toggleHover(false);
    }

    onDelete(charge_data) {
        this.setState({removing: true,}, () => {
            setTimeout(() => {
                this.props.onDelete(charge_data);
            }, 300);
        });
    }

    toggleHover(hover) {
        return e => {
            this.setState({hover});
        };
    }

    getHoverStyle() {
        let hoverStyles = {
            visibility: 'hidden',
        };
        if (this.state.hover) {
            hoverStyles = {hoverStyles: 'visible'};
        }
        return hoverStyles;
    }

    renderParentItem() {
        const {charge_data, charge_type, can_edit, onChange, onUpdateRequest, } = this.props;

        return (
            <div>
                {charge_type=='fixed' ?
                    <div className="columns large-2 medium-5 small-5">
                        {Label("Increment", "increment-"+charge_data.charge_id, {className: !can_edit && !this.props.charge_data.qty ? 'error' : ''})}
                        <input type="number" min="0"
                            className={!can_edit && !this.props.charge_data.qty ? 'error' : ''}
                            placeholder={!can_edit && !this.props.charge_data.qty ? 'Not filled in' : ''}
                            value={this.props.charge_data.qty}
                            style={{
                                textAlign: 'left',
                                ...(can_edit === false ?  {background: 'white', border: 0, boxShadow: 'none'}: {}),
                            }}
                            disabled={can_edit === false}
                            onChange={(e) => {
                                const val = parseFloat(e.target.value);
                                if (!isNaN(e.target.value) || e.target.value=='') {
                                    onChange({
                                        qty: e.target.value,
                                        charge_id: charge_data.charge_id,
                                        type: 'list-item',
                                        ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
                                    });
                                }
                            }}
                            onBlur={(e) => {
                                const val = parseFloat(e.target.value);
                                if ((!isNaN(e.target.value) && val > 0.0001) || e.target.value=='') {
                                    onUpdateRequest({
                                        qty: e.target.value,
                                        charge_id: charge_data.charge_id,
                                        type: 'list-item',
                                        ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
                                    });
                                }
                            }}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>
                : null}
                <div className="columns large-2 medium-5 small-5">
                    {Label("Units", "unit-"+charge_data.charge_id, {className: !can_edit && !this.props.charge_data.unit ? 'error' : ''})}
                    <TextInput placeholder={!can_edit && !this.props.charge_data.unit ? 'Not filled in' : '(colors, stitches, ...)'}
                        className={!can_edit && !this.props.charge_data.unit ? 'error' : ''}
                        style={{textAlign: 'left', ...(can_edit === false ?  {background: 'white', border: 0, boxShadow: 'none'}: {}),}}
                        disabled={can_edit === false}
                        value={this.props.charge_data.unit}
                        onChange={(e) => {
                            onChange({
                                unit: e.target.value,
                                charge_id: charge_data.charge_id,
                                type: 'list-item',
                                ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
                            });
                        }}
                        onBlur={(e) => {
                            onUpdateRequest({
                                unit: e.target.value,
                                charge_id: charge_data.charge_id,
                                type: 'list-item',
                                ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
                            });
                        }}
                        onFocus={(e) => e.target.select()}
                    />
                </div>
                <hr style={{maxWidth: 'inherit'}} />
            </div>
        );
    }

    render() {
        const {charge_data, parent_data,  onChange, can_edit, charge_type} = this.props;
        const {removing} = this.state;

        if (this.props.is_parent) {
            return (
                <div style={{
                    marginTop: 5,
                    transition: 'all 250ms linear',
                    // opacity: removing ? 0 : 'unset',
                }}>{this.renderParentItem()}</div>
            );
        }

        return (
            <div style={{marginTop: 5, transition: 'all 250ms linear',
                // opacity: removing ? 0 : 'unset',
            }}
                onMouseEnter={this._hoverOn}
                onMouseLeave={this._hoverOff}
            >
                {can_edit ?
                    <div className="columns medium-1 delete" style={{textAlign: 'right', padding: 0, margin: 0, width: '2%', fontSize: '0.6em', marginTop: 5, }}>
                        <a style={{...this.getHoverStyle()}}
                            onClick={(e) => {
                                e.preventDefault();
                                this.onDelete(charge_data);
                            }}>&times;</a>
                    </div>
                : null}

                <div className="columns large-3 medium-4 small-11">
                    <TextInput
                        style={{
                            textAlign: 'left',
                            ...(can_edit === false ?  {background: 'white', border: 0, boxShadow: 'none'}: {}),
                        }}
                        className={!can_edit && !charge_data.label ? 'error' : ''}
                        disabled={can_edit === false}
                        value={charge_data.label}
                        placeholder={!can_edit && !charge_data.label ? 'Not filled in' : 'Add charge details'}
                        onChange={(e) => {
                            e.preventDefault();
                            // this.setState({label: e.target.value,});
                            onChange({
                                remote_update: false,
                                label: e.target.value,
                                charge_id: charge_data.charge_id,
                                type: 'list-item',
                                ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
                            });
                        }}
                        onBlur={(e) => {
                            e.preventDefault();
                            onChange({
                                label: e.target.value,
                                charge_id: charge_data.charge_id,
                                type: 'list-item',
                                ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
                            });
                        }}
                        onFocus={(e) => e.target.select()}
                    />
                </div>
                <div className="columns large-2 medium-3 small-5">
                    <div className="input-group" style={{ marginBottom: 0, }}>
                        <span className="input-group-label"
                            style={{
                                background: 'white',
                                border: 0,
                                borderWidth: 0,
                                fontSize: 17,
                                lineHeight: 'normal',
                                verticalAlign: 'middle',
                                paddingRight: 5,
                                paddingBottom: 20,
                                paddingLeft: 0,
                            }}>$</span>
                        <input className={'input-group-field ' + !can_edit && !this.state.cell_value ? 'error' : ''}
                            type="number" min="0"
                            placeholder={!can_edit && !this.state.cell_value ? 'Not filled in' : 'Price'}
                            value={this.state.cell_value}
                            disabled={can_edit === false}
                            style={{
                                textAlign: 'left',
                                borderRadius: 3,
                                marginTop: 0,
                                marginBottom: 20,
                                ...(can_edit === false ?  {background: 'white', border: 0, boxShadow: 'none'}: {}),
                            }}
                            onChange={(e) => {
                                e.preventDefault();
                                this.setState({cell_value: e.target.value, });

//                                    onChange({
//                                        remote_update: false,
//                                        cell_value: e.target.value,
//                                        charge_id: charge_data.charge_id,
//                                        type: 'list-item',
//                                        ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
//                                    });
//                                }
                            }}
                            onBlur={(e) => {
                                e.preventDefault();
                                const val = parseFloat(e.target.value);
                                if ((!isNaN(e.target.value) && val > 0.0001) || e.target.value=='') {
                                    this.setState({cell_value: convertToFloat(e.target.value)}, () => {
                                        onChange({
                                            cell_value: this.state.cell_value,
                                            charge_id: charge_data.charge_id,
                                            type: 'list-item',
                                            ...(this.props.is_child ? {parent_id: charge_data.parent_id} : {}),
                                        });
                                    });
                                }
                            }}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>
                </div>

                <div className={'columns large-2 medium-4 small-5'}
                    style={{
                        textAlign: 'left',
                        padding: 0,
                        margin: 0,
                        fontSize: 17,
                        marginTop: 7,
                    }}>
                    {charge_type == 'run' ?
                        'Per Item' :
                        `per ${parent_data.qty && parent_data.qty <= 1 ? '' : parent_data.qty} ${parent_data && parent_data.unit ? parent_data.unit : ''}`
                    }
                </div>

                <hr style={{maxWidth: 'inherit'}} />
            </div>
        );
    }
}
