import React, { Component } from 'react';
import { Checkbox } from './Checkbox';

export class ListItemPricingTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            calc_qty: 0,
        };
    }

    render() {
        const {
            charges,
            decoration_id,
            updateListCharges,
            updateChargesData,
            charge_type,
            charge_name,
            charge_data,
            isPresentation
        } = this.props;

        return (
            <div className="columns medium-12" style={{marginBottom: 30}}>
                {charges.map(v => {
                    return (
                        <ListItem
                            charge={v}
                            key={v.charge_id}
                            decoration_id={decoration_id}
                            updateCharge={updateListCharges}
                            updateChargesData={updateChargesData}
                            charge_type={charge_type}
                            charge_name={charge_name}
                            charge_data={charge_data}
                        />
                    );
                })}
            </div>
        );
    }
}

export class ListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            charge_id: this.props.charge.charge_id,
            selected: false,
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleCheckboxChange() {
        this.setState(prevState => ({selected: !prevState.selected}), () => {
            this.props.updateCharge(this.props.charge.charge_id, {
                checked: this.state.selected,
            });
        });
    }

    renderPerQtyUnit() {
        const {charge, charge_data: { charge_type, d_unit, d_qty }} = this.props;

        if (charge_type == 'run') {
            return `$${charge.cell_value} Per Unit`;
        }

        let result = '';
        if (charge.cell_value) {
            result += '$'+charge.cell_value;
        }
        if (charge_type == 'fixed') {
            result += ' per ' + (d_qty==1 ? '' : d_qty) + ' ' + d_unit;
        }

        return result;
    }

    render() {
        const { charge } = this.props;

        return (
            <div className="columns medium-12" style={{fontWeight: 'bold'}}>
                <div className="columns medium-4" style={{textAlign: 'left', marginTop: 10, }}>
                    {charge.label ? charge.label : <br />}
                </div>
                <div className="columns medium-3" style={{textAlign: 'left', marginTop: 10, }}>
                    {this.renderPerQtyUnit()}
                </div>
                <div className="columns medium-2" style={{paddingLeft: '0.5rem', paddingRight: 0, }}>
                    <Checkbox
                        checked={this.state.selected}
                        onChange={this.handleCheckboxChange}
                    />
                </div>
            </div>
        );
    }
}

